<template>
  <form-consulta-cadastro
    :formCadastro="formCadastro"
    :controller="controller"
  />
</template>

<script>
import { ConsultaServico } from '@/proxy-api/src/services/classes/Servico/ConsultaServico';

import FormConsultaCadastro from '@/components/form/FormConsultaCadastro';
import FormServico from '@/views/servico/form/FormServico';

export default {
  components: { FormConsultaCadastro },

  data: function () {
    return {
      controller: new ConsultaServico(),
      formCadastro: FormServico,
    };
  },
};
</script>
